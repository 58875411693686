import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _31036578 = () => interopDefault(import('../pages/achievement/index.vue' /* webpackChunkName: "pages/achievement/index" */))
const _ae086820 = () => interopDefault(import('../pages/advertise/index.vue' /* webpackChunkName: "pages/advertise/index" */))
const _609193da = () => interopDefault(import('../pages/agreement/index.vue' /* webpackChunkName: "pages/agreement/index" */))
const _624c05ab = () => interopDefault(import('../pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _fcad7b6a = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _e459186a = () => interopDefault(import('../pages/details/index.vue' /* webpackChunkName: "pages/details/index" */))
const _27c90349 = () => interopDefault(import('../pages/goals/index.vue' /* webpackChunkName: "pages/goals/index" */))
const _c84d6438 = () => interopDefault(import('../pages/maisoku/index.vue' /* webpackChunkName: "pages/maisoku/index" */))
const _a6b99b26 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _62b6a18e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _e162283c = () => interopDefault(import('../pages/project/index.vue' /* webpackChunkName: "pages/project/index" */))
const _5ec6f6ee = () => interopDefault(import('../pages/response/index.vue' /* webpackChunkName: "pages/response/index" */))
const _799381cb = () => interopDefault(import('../pages/achievement/output.vue' /* webpackChunkName: "pages/achievement/output" */))
const _cea32ffa = () => interopDefault(import('../pages/achievement/registration.vue' /* webpackChunkName: "pages/achievement/registration" */))
const _54dbb82e = () => interopDefault(import('../pages/advertise/analysis.vue' /* webpackChunkName: "pages/advertise/analysis" */))
const _3884a8d6 = () => interopDefault(import('../pages/advertise/management.vue' /* webpackChunkName: "pages/advertise/management" */))
const _23244a8b = () => interopDefault(import('../pages/advertise/registration.vue' /* webpackChunkName: "pages/advertise/registration" */))
const _544db870 = () => interopDefault(import('../pages/calendar/registration.vue' /* webpackChunkName: "pages/calendar/registration" */))
const _559107d0 = () => interopDefault(import('../pages/customer/registration.vue' /* webpackChunkName: "pages/customer/registration" */))
const _60179635 = () => interopDefault(import('../pages/goals/analysis.vue' /* webpackChunkName: "pages/goals/analysis" */))
const _412d3e07 = () => interopDefault(import('../pages/goals/setting.vue' /* webpackChunkName: "pages/goals/setting" */))
const _68cc9d7b = () => interopDefault(import('../pages/line/tracking/index.vue' /* webpackChunkName: "pages/line/tracking/index" */))
const _7c3fdfdc = () => interopDefault(import('../pages/maisoku/export.vue' /* webpackChunkName: "pages/maisoku/export" */))
const _01fa1c58 = () => interopDefault(import('../pages/maisoku/template.vue' /* webpackChunkName: "pages/maisoku/template" */))
const _65c065cd = () => interopDefault(import('../pages/response/registration.vue' /* webpackChunkName: "pages/response/registration" */))
const _0a5d1fda = () => interopDefault(import('../pages/setting/announcement/index.vue' /* webpackChunkName: "pages/setting/announcement/index" */))
const _5867bc9e = () => interopDefault(import('../pages/setting/import/index.vue' /* webpackChunkName: "pages/setting/import/index" */))
const _89e23152 = () => interopDefault(import('../pages/setting/mail-template.vue' /* webpackChunkName: "pages/setting/mail-template" */))
const _5427dd8e = () => interopDefault(import('../pages/setting/matching-mail.vue' /* webpackChunkName: "pages/setting/matching-mail" */))
const _97bd9682 = () => interopDefault(import('../pages/setting/shop.vue' /* webpackChunkName: "pages/setting/shop" */))
const _190052ac = () => interopDefault(import('../pages/setting/staff/index.vue' /* webpackChunkName: "pages/setting/staff/index" */))
const _a789033c = () => interopDefault(import('../pages/setting/step-mail/index.vue' /* webpackChunkName: "pages/setting/step-mail/index" */))
const _356d12a4 = () => interopDefault(import('../pages/setting/tag/index.vue' /* webpackChunkName: "pages/setting/tag/index" */))
const _cdbe7280 = () => interopDefault(import('../pages/shop/document-template/index.vue' /* webpackChunkName: "pages/shop/document-template/index" */))
const _286c775b = () => interopDefault(import('../pages/shop/message/index.vue' /* webpackChunkName: "pages/shop/message/index" */))
const _1efb3943 = () => interopDefault(import('../pages/message/line/edit.vue' /* webpackChunkName: "pages/message/line/edit" */))
const _33412e1b = () => interopDefault(import('../pages/message/line/log.vue' /* webpackChunkName: "pages/message/line/log" */))
const _4b6b8716 = () => interopDefault(import('../pages/message/line/registration/index.vue' /* webpackChunkName: "pages/message/line/registration/index" */))
const _94955a16 = () => interopDefault(import('../pages/message/line/select.vue' /* webpackChunkName: "pages/message/line/select" */))
const _09d8abc1 = () => interopDefault(import('../pages/message/line/send.vue' /* webpackChunkName: "pages/message/line/send" */))
const _4785de44 = () => interopDefault(import('../pages/message/mail/log.vue' /* webpackChunkName: "pages/message/mail/log" */))
const _37e51c0f = () => interopDefault(import('../pages/message/mail/registration.vue' /* webpackChunkName: "pages/message/mail/registration" */))
const _e422555c = () => interopDefault(import('../pages/message/mail/select.vue' /* webpackChunkName: "pages/message/mail/select" */))
const _ff4dbd44 = () => interopDefault(import('../pages/message/mail/send.vue' /* webpackChunkName: "pages/message/mail/send" */))
const _7c374908 = () => interopDefault(import('../pages/setting/announcement/registration.vue' /* webpackChunkName: "pages/setting/announcement/registration" */))
const _9645ffbc = () => interopDefault(import('../pages/setting/import/Detail.vue' /* webpackChunkName: "pages/setting/import/Detail" */))
const _42fd29aa = () => interopDefault(import('../pages/setting/import/registration.vue' /* webpackChunkName: "pages/setting/import/registration" */))
const _7372048a = () => interopDefault(import('../pages/setting/real-estate-agent/property-range.vue' /* webpackChunkName: "pages/setting/real-estate-agent/property-range" */))
const _017b9a91 = () => interopDefault(import('../pages/setting/staff/registration.vue' /* webpackChunkName: "pages/setting/staff/registration" */))
const _c303584e = () => interopDefault(import('../pages/setting/step-mail/registration.vue' /* webpackChunkName: "pages/setting/step-mail/registration" */))
const _13f4fc66 = () => interopDefault(import('../pages/message/line/registration/success.vue' /* webpackChunkName: "pages/message/line/registration/success" */))
const _4ae1f558 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d9ece6ea = () => interopDefault(import('../pages/message/mail/edit/_id.vue' /* webpackChunkName: "pages/message/mail/edit/_id" */))
const _7e1be0f0 = () => interopDefault(import('../pages/agreement/log/_id.vue' /* webpackChunkName: "pages/agreement/log/_id" */))
const _207e4cb3 = () => interopDefault(import('../pages/agreement/registration/_id.vue' /* webpackChunkName: "pages/agreement/registration/_id" */))
const _8188fe68 = () => interopDefault(import('../pages/customer/edit/_id.vue' /* webpackChunkName: "pages/customer/edit/_id" */))
const _25f0f419 = () => interopDefault(import('../pages/media/registration/_id.vue' /* webpackChunkName: "pages/media/registration/_id" */))
const _5f182f84 = () => interopDefault(import('../pages/project/registration/_id.vue' /* webpackChunkName: "pages/project/registration/_id" */))
const _33140130 = () => interopDefault(import('../pages/response/add/_id.vue' /* webpackChunkName: "pages/response/add/_id" */))
const _06125b3b = () => interopDefault(import('../pages/setting/announcement/_id.vue' /* webpackChunkName: "pages/setting/announcement/_id" */))
const _f0f108dc = () => interopDefault(import('../pages/setting/staff/_id.vue' /* webpackChunkName: "pages/setting/staff/_id" */))
const _d80c9d6c = () => interopDefault(import('../pages/setting/step-mail/_id.vue' /* webpackChunkName: "pages/setting/step-mail/_id" */))
const _d852d6fa = () => interopDefault(import('../pages/shop/message/_id.vue' /* webpackChunkName: "pages/shop/message/_id" */))
const _369c65e0 = () => interopDefault(import('../pages/achievement/_id.vue' /* webpackChunkName: "pages/achievement/_id" */))
const _45b1a13b = () => interopDefault(import('../pages/agreement/_id.vue' /* webpackChunkName: "pages/agreement/_id" */))
const _53443ab2 = () => interopDefault(import('../pages/client-history/_id.vue' /* webpackChunkName: "pages/client-history/_id" */))
const _56a95b73 = () => interopDefault(import('../pages/customer/_id.vue' /* webpackChunkName: "pages/customer/_id" */))
const _0b7627d6 = () => interopDefault(import('../pages/media/_id.vue' /* webpackChunkName: "pages/media/_id" */))
const _380a7eca = () => interopDefault(import('../pages/project/_id.vue' /* webpackChunkName: "pages/project/_id" */))
const _3fd11e38 = () => interopDefault(import('../pages/property/_property.vue' /* webpackChunkName: "pages/property/_property" */))
const _287c9f90 = () => interopDefault(import('../pages/property/_property/contact/index.vue' /* webpackChunkName: "pages/property/_property/contact/index" */))
const _057e8ba6 = () => interopDefault(import('../pages/property/_property/contact/confirm.vue' /* webpackChunkName: "pages/property/_property/contact/confirm" */))
const _c47602dc = () => interopDefault(import('../pages/property/_property/contact/send.vue' /* webpackChunkName: "pages/property/_property/contact/send" */))
const _c03c4654 = () => interopDefault(import('../pages/response/_id.vue' /* webpackChunkName: "pages/response/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievement",
    component: _31036578,
    name: "achievement"
  }, {
    path: "/advertise",
    component: _ae086820,
    name: "advertise"
  }, {
    path: "/agreement",
    component: _609193da,
    name: "agreement"
  }, {
    path: "/calendar",
    component: _624c05ab,
    name: "calendar"
  }, {
    path: "/customer",
    component: _fcad7b6a,
    name: "customer"
  }, {
    path: "/details",
    component: _e459186a,
    name: "details"
  }, {
    path: "/goals",
    component: _27c90349,
    name: "goals"
  }, {
    path: "/maisoku",
    component: _c84d6438,
    name: "maisoku"
  }, {
    path: "/media",
    component: _a6b99b26,
    name: "media"
  }, {
    path: "/privacy",
    component: _62b6a18e,
    name: "privacy"
  }, {
    path: "/project",
    component: _e162283c,
    name: "project"
  }, {
    path: "/response",
    component: _5ec6f6ee,
    name: "response"
  }, {
    path: "/achievement/output",
    component: _799381cb,
    name: "achievement-output"
  }, {
    path: "/achievement/registration",
    component: _cea32ffa,
    name: "achievement-registration"
  }, {
    path: "/advertise/analysis",
    component: _54dbb82e,
    name: "advertise-analysis"
  }, {
    path: "/advertise/management",
    component: _3884a8d6,
    name: "advertise-management"
  }, {
    path: "/advertise/registration",
    component: _23244a8b,
    name: "advertise-registration"
  }, {
    path: "/calendar/registration",
    component: _544db870,
    name: "calendar-registration"
  }, {
    path: "/customer/registration",
    component: _559107d0,
    name: "customer-registration"
  }, {
    path: "/goals/analysis",
    component: _60179635,
    name: "goals-analysis"
  }, {
    path: "/goals/setting",
    component: _412d3e07,
    name: "goals-setting"
  }, {
    path: "/line/tracking",
    component: _68cc9d7b,
    name: "line-tracking"
  }, {
    path: "/maisoku/export",
    component: _7c3fdfdc,
    name: "maisoku-export"
  }, {
    path: "/maisoku/template",
    component: _01fa1c58,
    name: "maisoku-template"
  }, {
    path: "/response/registration",
    component: _65c065cd,
    name: "response-registration"
  }, {
    path: "/setting/announcement",
    component: _0a5d1fda,
    name: "setting-announcement"
  }, {
    path: "/setting/import",
    component: _5867bc9e,
    name: "setting-import"
  }, {
    path: "/setting/mail-template",
    component: _89e23152,
    name: "setting-mail-template"
  }, {
    path: "/setting/matching-mail",
    component: _5427dd8e,
    name: "setting-matching-mail"
  }, {
    path: "/setting/shop",
    component: _97bd9682,
    name: "setting-shop"
  }, {
    path: "/setting/staff",
    component: _190052ac,
    name: "setting-staff"
  }, {
    path: "/setting/step-mail",
    component: _a789033c,
    name: "setting-step-mail"
  }, {
    path: "/setting/tag",
    component: _356d12a4,
    name: "setting-tag"
  }, {
    path: "/shop/document-template",
    component: _cdbe7280,
    name: "shop-document-template"
  }, {
    path: "/shop/message",
    component: _286c775b,
    name: "shop-message"
  }, {
    path: "/message/line/edit",
    component: _1efb3943,
    name: "message-line-edit"
  }, {
    path: "/message/line/log",
    component: _33412e1b,
    name: "message-line-log"
  }, {
    path: "/message/line/registration",
    component: _4b6b8716,
    name: "message-line-registration"
  }, {
    path: "/message/line/select",
    component: _94955a16,
    name: "message-line-select"
  }, {
    path: "/message/line/send",
    component: _09d8abc1,
    name: "message-line-send"
  }, {
    path: "/message/mail/log",
    component: _4785de44,
    name: "message-mail-log"
  }, {
    path: "/message/mail/registration",
    component: _37e51c0f,
    name: "message-mail-registration"
  }, {
    path: "/message/mail/select",
    component: _e422555c,
    name: "message-mail-select"
  }, {
    path: "/message/mail/send",
    component: _ff4dbd44,
    name: "message-mail-send"
  }, {
    path: "/setting/announcement/registration",
    component: _7c374908,
    name: "setting-announcement-registration"
  }, {
    path: "/setting/import/Detail",
    component: _9645ffbc,
    name: "setting-import-Detail"
  }, {
    path: "/setting/import/registration",
    component: _42fd29aa,
    name: "setting-import-registration"
  }, {
    path: "/setting/real-estate-agent/property-range",
    component: _7372048a,
    name: "setting-real-estate-agent-property-range"
  }, {
    path: "/setting/staff/registration",
    component: _017b9a91,
    name: "setting-staff-registration"
  }, {
    path: "/setting/step-mail/registration",
    component: _c303584e,
    name: "setting-step-mail-registration"
  }, {
    path: "/message/line/registration/success",
    component: _13f4fc66,
    name: "message-line-registration-success"
  }, {
    path: "/",
    component: _4ae1f558,
    name: "index"
  }, {
    path: "/message/mail/edit/:id?",
    component: _d9ece6ea,
    name: "message-mail-edit-id"
  }, {
    path: "/agreement/log/:id?",
    component: _7e1be0f0,
    name: "agreement-log-id"
  }, {
    path: "/agreement/registration/:id?",
    component: _207e4cb3,
    name: "agreement-registration-id"
  }, {
    path: "/customer/edit/:id?",
    component: _8188fe68,
    name: "customer-edit-id"
  }, {
    path: "/media/registration/:id?",
    component: _25f0f419,
    name: "media-registration-id"
  }, {
    path: "/project/registration/:id?",
    component: _5f182f84,
    name: "project-registration-id"
  }, {
    path: "/response/add/:id?",
    component: _33140130,
    name: "response-add-id"
  }, {
    path: "/setting/announcement/:id",
    component: _06125b3b,
    name: "setting-announcement-id"
  }, {
    path: "/setting/staff/:id",
    component: _f0f108dc,
    name: "setting-staff-id"
  }, {
    path: "/setting/step-mail/:id?",
    component: _d80c9d6c,
    name: "setting-step-mail-id"
  }, {
    path: "/shop/message/:id",
    component: _d852d6fa,
    name: "shop-message-id"
  }, {
    path: "/achievement/:id",
    component: _369c65e0,
    name: "achievement-id"
  }, {
    path: "/agreement/:id",
    component: _45b1a13b,
    name: "agreement-id"
  }, {
    path: "/client-history/:id?",
    component: _53443ab2,
    name: "client-history-id"
  }, {
    path: "/customer/:id",
    component: _56a95b73,
    name: "customer-id"
  }, {
    path: "/media/:id",
    component: _0b7627d6,
    name: "media-id"
  }, {
    path: "/project/:id",
    component: _380a7eca,
    name: "project-id"
  }, {
    path: "/property/:property?",
    component: _3fd11e38,
    name: "property-property",
    children: [{
      path: "contact",
      component: _287c9f90,
      name: "property-property-contact"
    }, {
      path: "contact/confirm",
      component: _057e8ba6,
      name: "property-property-contact-confirm"
    }, {
      path: "contact/send",
      component: _c47602dc,
      name: "property-property-contact-send"
    }]
  }, {
    path: "/response/:id",
    component: _c03c4654,
    name: "response-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
